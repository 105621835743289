
/**
 * Cwu settings for the CWU types of projects
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import {Component, Prop, Watch, Mixins} from "vue-property-decorator";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import {mapGetters, mapActions} from "vuex";
import Multiselect from "vue-multiselect";
import Section from "@/model/ModelSection";
import Project from "@/model/Project";
import {ActionMessage} from "@/model/Messages/statusMessage";
import {WEP_MOCK_URL} from "@/shared/keycloak";
import UserAccess from "@/model/User/UserAccess";
import CwuSettingsFullSize from "@/components/project/SingleProject/Schematic/VwaSettingsFullSize.vue";
import CwuSettingsTMD from "@/components/project/SingleProject/Schematic/VwaSettingsTMD.vue";

@Component({
  name: "VwaPreWarningLamp",
  components: {},

})
export default class VwaPreWarningLamp extends Mixins(confirmModal) {
  WEP_MOCK_URL = WEP_MOCK_URL;
  /*----------  Vuex  ----------*/
  readonly currentSection!: Section;
  private readonly manualOverrideOptions!: object;
  readonly currentProject!: Project;
  readonly getUserAccess!: UserAccess;

  private getSectionsDetails!: Function;
  private GetManualOverrideSignOption!: Function;
  private manualOverrideToggle!: Function;

  /*----------  Props  ----------*/
  @Prop({type: Object, default: () => ({})}) sectionInformation!: Section;
  @Prop({type: String, default: "48x48"}) displaySize!: string;
  @Prop({type: Boolean, default: false }) prewarningEnabled!: boolean;
  @Prop({type: String, default: "big'" }) size!: string;

  width: any = "45";
  height: any = "45";

  created() {
    if(this.size == 'small') {
      this.width = '15';
      this.height = '15';
    }
  }

}
