var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.vwaSettings.filename)?_c('div',[(_vm.vwaSettings.filename)?_c('div',{style:({
    backgroundImage: ("url(" + _vm.WEP_MOCK_URL + "/img/64x144/" + (_vm.vwaSettings.filename ? _vm.vwaSettings.filename : 'noPicture.jpg') + ")"),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    height: _vm.backgroundSizeFull,
    width: '100%',
    })},[(_vm.vwaSettings.prewarningEnabled)?_c('div',[_c('VwaPreWarningLamp',{attrs:{"prewarningEnabled":true,"size":'small'}})],1):_vm._e()]):_vm._e()]):(
              _vm.vwaSettings.filenameTop ||
              _vm.vwaSettings.filenameMid ||
              _vm.vwaSettings.filenameBottom)?_c('div',[(_vm.vwaSettings.filenameTop)?_c('div',{style:({
    backgroundImage: ("url(" + _vm.WEP_MOCK_URL + "/img/64x144/TrafficSignImagesTop64x56/" + (_vm.vwaSettings.filenameTop ? _vm.vwaSettings.filenameTop : 'noPicture.jpg') + ")"),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: _vm.backgroundSizeTop,
    height: _vm.backgroundSizeTop,
    width: '100%',
    })},[(_vm.vwaSettings.prewarningEnabled)?_c('div',[_c('VwaPreWarningLamp',{attrs:{"prewarningEnabled":true,"size":'small'}})],1):_vm._e()]):_c('div',{style:({ height: _vm.backgroundSizeTop })},[(_vm.vwaSettings.prewarningEnabled)?_c('div',[_c('VwaPreWarningLamp',{attrs:{"prewarningEnabled":true,"size":'small'}})],1):_vm._e()]),(_vm.vwaSettings.filenameMid)?_c('div',{style:({
    backgroundImage: ("url(" + _vm.WEP_MOCK_URL + "/img/64x144/" + _vm.folderMid + "/" + (_vm.vwaSettings.filenameMid ? _vm.vwaSettings.filenameMid : 'noPicture.jpg') + ")"),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: _vm.backgroundSizeHeightMid,
    height: _vm.backgroundSizeMid,
    width: '100%',
    })}):_c('div',{style:({ height: _vm.backgroundSizeMid })}),(_vm.vwaSettings.filenameBottom)?_c('div',{style:({
    backgroundImage: ("url(" + _vm.WEP_MOCK_URL + "/img/64x144/" + _vm.folderBottom + "/" + (_vm.vwaSettings.filenameBottom ? _vm.vwaSettings.filenameBottom : 'noPicture.jpg') + ")"),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: '58px',
    height: '70px',
    width: '100%',
    marginTop: (_vm.marginTop + " !important")
    })}):_c('div',{staticStyle:{"height":"70px","display":"inline-block"}})]):(_vm.vwaSettings.text)?_c('div',{staticClass:"vwa-led-sign-text",style:({
    'font-size': _vm.vwaSettings.textSize == 1 ? '8.5px' : _vm.vwaSettings.textSize == 2 ? '17px' : '4.5px',
    'line-height': _vm.infoView ? 0.92 : 0.78
  })},[_vm._v(_vm._s(_vm.vwaSettings.text)+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }