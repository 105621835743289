
/**
 * Create new section  with the information type.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */

import { Component, Mixins, Prop } from "vue-property-decorator";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import VwaPreWarningLamp from "@/components/project/SingleProject/Schematic/VwaPreWarningLamp.vue";
import {WEP_MOCK_URL} from "@/shared/keycloak";

@Component({
  name: "VwaLedSign",
  components: {
    VwaPreWarningLamp
  },
  computed: {
  }
})
export default class VwaType extends Mixins(KmFormatted) {
  WEP_MOCK_URL = WEP_MOCK_URL;

  @Prop({type: Object, default: () => ({})}) vwaSettings!: any;
  @Prop({type: String, default: '56px' }) backgroundSizeTop!: string;
  @Prop({type: String, default: '68px' }) backgroundSizeMid!: string;
  @Prop({type: String, default: '143px' }) backgroundSizeFull!: string;
  @Prop({type: String, default: '55px' }) backgroundSizeHeightMid!: string;
  @Prop({type: String, default: '' }) marginTop!: string;
  @Prop({ type: Boolean, default: false }) infoView!: boolean;

  swapSelects: boolean = false;
  folderMid: String = "TrafficSignImagesMid64x72";
  folderBottom: String = "TrafficSignImagesBottom64x16";



  mounted() {

    if(this.vwaSettings.filenameMid.charAt(0) == 'm') {
      this.folderMid = "TrafficSignImagesMid64x72";
      this.folderBottom = "TrafficSignImagesBottom64x16";

      this.backgroundSizeHeightMid = "54px";
      // this.backgroundSizeMid = "60px";

    } else {

      this.folderMid = "TrafficSignImagesBottom64x16";
      this.folderBottom = "TrafficSignImagesMid64x72";
    }
  }
}
