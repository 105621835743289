
/**
 * Cwu settings for the CWU types of projects
 *
 * @author IboMade GmbH
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import Section from "@/model/ModelSection";
import Project from "@/model/Project";
import { ActionMessage } from "@/model/Messages/statusMessage";
import { WEP_MOCK_URL } from "@/shared/keycloak";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "VwaSettingsTMD",
  components: {
    Multiselect,
  },
  computed: {
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject"
    }),
  },

})
export default class VwaSettingsTMD extends Mixins(confirmModal) {
  WEP_MOCK_URL = WEP_MOCK_URL;
  /*----------  Vuex  ----------*/
  readonly currentProject!: Project;

  /*----------  Props  ----------*/
  @Prop({ type: Object, default: () => ({}) }) sectionInformation!: Section;
  @Prop({ type: String, default: "64x144" }) displaySize!: string;
  @Prop({ type: Array }) options!: any;
  @Prop({ type: String, default: "" }) folder!: string;
  @Prop({ type: Object }) loadSelectedOption!: any;

  private selectedOptions: Array<any> =  [];


  /*----------  Vue life cycles  ----------*/
  created() {
  }

  mounted() {
    this.renderSelectedOptionsOnLoad();
  }

  renderSelectedOptionsOnLoad() {
    this.selectedOptions = (this.loadSelectedOption) ? this.loadSelectedOption : [];
  }

  emitSelection() {

    this.$emit('selectionChanged', {
      folder: this.folder,
      selectedOption: this.selectedOptions
    });
  }
}
