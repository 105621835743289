
/**
 * Road Zipper scene options
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Mixins, Watch } from "vue-property-decorator";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import { mapGetters, mapActions, ActionMethod } from "vuex";
import Project from "@/model/Project";
import { ActionMessage } from "@/model/Messages/statusMessage";
import UserAccess from "@/model/User/UserAccess";
import { Scene } from "@/interface/projectsInterface";

@Component({
  name: "ScenesOptions",
  computed: {
    ...mapGetters(["isUserAdmin", "getUserAccess"]),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      ACTIVE_SCENE: "GetActiveScene",
      projectScenes: "GetAllScenes"
    })
  },
  methods: {
    ...mapActions("projects", {
      GET_ACTIVE_SCENE: "GetActiveSceneForSceneBasedProject",
      SWITCH_SCENE: "SceneProjectSwitch"
    })
  }
})
export default class ScenesOptions extends Mixins(confirmModal) {
  /*----------  Vuex  ----------*/
  private readonly ACTIVE_SCENE!: Scene;
  readonly currentProject!: Project;
  readonly isUserAdmin!: boolean;
  readonly getUserAccess!: UserAccess;
  readonly projectScenes!: Scene[];

  private GET_ACTIVE_SCENE!: Function;
  private SWITCH_SCENE!: ActionMethod;

  /*----------  local data  ----------*/
  activeScene: Scene | any = { id: 0, name: "default" };
  sceneUpdateStatus: ActionMessage | null = null;

  /*----------  lifeCycles  ----------*/
  async created() {
    await this.GET_ACTIVE_SCENE(this.currentProject.id);
    this.activeScene = this.ACTIVE_SCENE;
  }

  /*----------  Watch  ----------*/
  @Watch("ACTIVE_SCENE", { immediate: true })
  async HandleSceneUpdate() {
    this.activeScene = this.ACTIVE_SCENE;
  }

  /*----------  Methods  ----------*/
  HandleSceneSelected(itemToUpdate: Scene) {
    this.sceneUpdateStatus = null;
    this.confirm(
      this.$t("project.roadZipper.sceneConfirm.confirm").toString(),
      async () => {
        // Update the scene to the new one
        let res = await this.SWITCH_SCENE({
          projectId: this.currentProject.id,
          sceneId: itemToUpdate.id
        });
        if (res.status !== 200) {
          this.sceneUpdateStatus = {
            class: "danger",
            msg: res
          };
          return;
        }
        this.sceneUpdateStatus = {
          class: "success",
          msg: this.$t("project.roadZipper.sceneConfirm.sceneSuccess").toString()
        };
        await this.GET_ACTIVE_SCENE(this.currentProject.id);
      },
      () => {
        this.GET_ACTIVE_SCENE(this.currentProject.id);
      }
    );
  }
}
